<template>
  <div>
    <h3 class="mb-1 mt-1">
      Status Cetak Anggota
    </h3>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mb-1"
      @click="openModal()"
    >
      <b-spinner v-show="btnDownloadDisabled" small type="grow" />
      <feather-icon icon="CreditCardIcon" class="mr-50" />
      <span class="align-middle">Ajukan Cetak Kartu</span>
    </b-button>
    <!-- {{ items }} -->
    <vue-good-table
      :columns="columns"
      :rows="items"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      @on-select-all="onSelectAll"
      @on-row-click="onRowClick"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <div v-if="props.column.field === 'id'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.id }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="download(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Download Foto</span>
              </b-dropdown-item>
              <b-dropdown-item @click="downloadQR(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Download QR</span>
              </b-dropdown-item>
              <b-dropdown-item @click="openModalSingle(props.row.id)">
                <feather-icon icon="CreditCardIcon" class="mr-50" />
                <span>Ajukan Cetak</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <!-- Column: Jml order -->
        <div
          v-else-if="props.column.field === 'recent_order_status'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.recent_order_status }}</span>
        </div>
        <!-- Column: tanggal order -->
        <div
          v-else-if="props.column.field === 'recent_order_created_at'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{
            props.row.recent_order_created_at
          }}</span>
          <!-- <span
            v-if="props.row.recent_order_created_at === null"
            class="text-nowrap"
            >none</span
          >
          <span v-else>{{ props.row.recent_order_created_at }} - mantap</span> -->
        </div>
      </template>
    </vue-good-table>
    <b-modal ref="modalPengajuan" size="lg" title="Pengajuan" hide-footer>
      <b-row>
        <b-col sm="12">
          Anda mengajukan {{ selectedCount }} pengajuan data untuk dicetak,
          silakan pilih jenis pengajuan berikut
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-form-select
            v-model="localOptions.jenisPengajuan"
            :options="optionsJenis"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="btnSimpanDisabled"
            @click="ajukan('multi')"
          >
            <b-spinner v-show="btnSimpanSpinnerShow" small type="grow" />
            {{ labelBtnAjukan ? 'Ajukan' : 'Loading ...' }}
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="show = false"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal ref="modalPengajuanSingle" size="lg" title="Pengajuan" hide-footer>
      <b-row>
        <b-col sm="12">
          Anda mengajukan {{ selectedCount }} pengajuan data untuk dicetak,
          silakan pilih jenis pengajuan berikut
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-form-select
            v-model="localOptions.jenisPengajuan"
            :options="optionsJenis"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mt-2 mr-1"
            @click="ajukan('singleselected')"
          >
            Ajukan
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="show = false"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-overlay :show="processing" no-wrap>
      <template #overlay>
        <b-spinner variant="primary" label="Text Centered" />
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  // BAvatar,
  // BBadge,
  // BPagination,
  // BFormGroup,
  // BFormInput,
  // BFormSelect,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BSpinner,
  BButton,
  BModal,
  BRow,
  BCol,
  BFormSelect,
} from 'bootstrap-vue'
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'
import { api, download } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueGoodTable,
    // BAvatar,
    // BBadge,
    // BPagination,
    // BFormGroup,
    // BFormInput,
    // BFormSelect,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BSpinner,
    BButton,
    BModal,
    BRow,
    BCol,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localOptions: {
        jenisPengajuan: null,
      },
      localData: {},
      test: null,
      processing: false,
      btnDownloadDisabled: false,
      selectedCount: 0,
      selectedData: [],
      selectedDataSingle: [],
      pageLength: 20,
      items: [],
      labelBtnAjukan: true,
      btnSimpanDisabled: false,
      btnSimpanSpinnerShow: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Nama Lengkap',
          field: 'nama_lengkap',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Nama',
          },
        },
        {
          label: 'Nama KTPA',
          field: 'nama_ktpa',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Nama KTPA',
          },
        },
        {
          label: 'NIA',
          field: 'nia',
          filterOptions: {
            enabled: true,
            placeholder: 'Search NIA',
          },
        },
        {
          label: 'Berlaku',
          field: 'tanggal_berakhir',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'DPC',
          field: 'dpn_dpc_nama',
          filterOptions: {
            enabled: true,
            placeholder: 'Search DPC',
          },
        },
        {
          label: 'Last Order Cetak',
          field: 'recent_order_created_at',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Status Cetak',
          field: 'recent_order_status',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Jenis',
          field: 'recent_order_jenis',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      optionsJenis: [
        { value: null, text: '--Pilih Jenis--' },
        { value: 'baru', text: 'Baru' },
        { value: 'perpanjangan', text: 'Perpanjangan' },
        { value: 'penggantian', text: 'Penggantian' },
      ],
    }
  },
  async mounted() {
    this.mounting()
  },
  methods: {
    async mounting() {
      this.processing = true
      try {
        const result = await api().get('pencetakan/anggota-aktif')
        this.items = []
        result.data.items.forEach(element => {
          // eslint-disable-next-line no-param-reassign
          element.recent_order_created_at = element.recent_order_created_at
            ? moment(element.recent_order_created_at).format('YYYY-MM-DD')
            : 'none'

          // eslint-disable-next-line no-param-reassign
          element.recent_order_status = element.recent_order_status
            ? element.recent_order_status
            : 'none'

          // eslint-disable-next-line no-param-reassign
          element.tanggal_berakhir = moment(element.tanggal_berakhir).format(
            'YYYY-MM-DD',
          )
          this.items.push(element)
        })
      } catch (error) {
        alert(error.response.data.message)
      }
      this.processing = false
    },
    testRender(instance, td, row, col, prop, value) {
      // console.log(value)
      // eslint-disable-next-line no-param-reassign
      td.innerHTML = value.length
    },
    async download(row) {
      // const fileUrl = `${fileServerUrl}/biodata/${this.masterDokumen.biodata_id}/${this.masterDokumen.data[filename]}`
      // window.open(fileUrl)

      // alert(this.masterDokumen.data[filename])
      const filename = row.file_foto
      const biodataID = row.id

      try {
        this.processing = true
        // console.log(row)
        // await new Promise(r => setTimeout(r, 2000))
        const result = await download().get(
          `biodata/download/${biodataID}/${filename}`,
        )
        // console.log(result)
        // this.fileQR = result.data.url
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.processing = false
    },
    async downloadExcel() {
      try {
        this.processing = true
        // console.log(row)
        // await new Promise(r => setTimeout(r, 2000))
        const result = await download().get(
          'search/biodata/ready-cetak?tanggal_berakhir=2022-01-01&export=xls',
        )
        // console.log(result)
        // this.fileQR = result.data.url
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ready_cetak.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.processing = false
    },
    async downloadQR(row) {
      this.processing = true
      const biodataID = row.id
      try {
        const result = await download().get(`qr/code/${biodataID}/download`)
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `qr_${row.nia}.png`) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.processing = false
    },
    openModal() {
      // eslint-disable-next-line dot-notation
      this.$refs['modalPengajuan'].show()
    },
    openModalSingle(idStatus) {
      this.resetSingle()

      this.selectedDataSingle.push({ id: Number(idStatus) })
      this.selectedCount = this.selectedDataSingle.length

      // eslint-disable-next-line dot-notation
      this.$refs['modalPengajuanSingle'].show()
    },
    closeModal(param) {
      if (param === 'multi') {
        // eslint-disable-next-line dot-notation
        this.$refs['modalPengajuan'].hide()
      } else {
        // eslint-disable-next-line dot-notation
        this.$refs['modalPengajuanSingle'].hide()
      }
    },
    onSelectAll(params) {
      if (params.selected) {
        this.reset()
        this.selectedCount = params.selectedRows.length
        params.selectedRows.forEach(element => {
          this.selectedData.push({ id: element.id })
        })
      } else {
        this.reset()
      }
    },
    async onRowClick(params) {
      if (params.selected) {
        this.selectedData.push({ id: params.row.id })
        this.selectedCount = this.selectedData.length
      } else {
        this.selectedData.forEach((element, index) => {
          if (element.id === params.row.id) {
            this.selectedData.splice(index, 1)
            this.selectedCount = this.selectedData.length
          }
        })
      }
    },
    reset() {
      this.localOptions.jenisPengajuan = null
      this.selectedData = []
      this.selectedCount = 0
    },
    resetSingle() {
      this.localOptions.jenisPengajuan = null
      this.selectedDataSingle = []
      this.selectedCount = 0
    },
    async ajukan(param) {
      this.labelBtnAjukan = false
      this.btnSimpanSpinnerShow = true
      this.btnSimpanDisabled = true

      if (!this.localOptions.jenisPengajuan) {
        this.makeToast(
          'danger',
          'Gagal',
          'Jenis Pengajuan Tidak Boleh Kosong',
          true,
        )
      } else if (this.selectedCount === 0) {
        this.makeToast(
          'danger',
          'Gagal',
          'Harap Memilih Satu atau Lebih Data Terlebih Dahulu',
          true,
        )
      } else {
        const temp = []
        if (param === 'singleselected') {
          this.selectedDataSingle.forEach(element => {
            if (!Array.isArray(element)) {
              temp.push(element.id)
            }
          })
        } else {
          this.selectedData.forEach(element => {
            if (!Array.isArray(element)) {
              temp.push(element.id)
            }
          })
        }

        try {
          const tmpData = {
            daftar_biodata: temp,
            jenis: this.localOptions.jenisPengajuan,
          }
          const request = await api().post('pencetakan/order', tmpData)
          if (request.data.requested) {
            this.makeToast('success', 'Sukses', 'Data berhasil diajukan')
            this.closeModal(param)
            this.mounting()
            this.reset()
          } else {
            this.makeToast('danger', 'Error', 'Data gagal diajukan, silakan coba kembali', true)
            this.closeModal(param)
            this.reset()
          }
          this.btnSimpanSpinnerShow = false
          this.btnSimpanDisabled = false
          this.labelBtnAjukan = true
        } catch (error) {
          this.makeToast('danger', 'Error', error.response.data.message, true)
          this.btnSimpanSpinnerShow = false
          this.btnSimpanDisabled = false
          this.labelBtnAjukan = true
        }
      }
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 3000,
        noAutoHide,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
